.bar-dis {
  width: 100;
  height: 4px;
  background: #272424;
  border-radius: 0px;
  overflow: hidden;
}

.bar-dis-2 {
  width: calc(100% - 30px);
  height: 2px;
  position: absolute;
  z-index: 99999;
  background: #ffffff;
  border-radius: 0px;
  overflow: hidden;
}

.bar {
  height: 100%;
  background: #ffffff;
  animation: samet 20s ease;
  animation-iteration-count: infinite;
}

.trending {
  padding-left: 124px;
  padding-right: 124px;
  padding-top: 64px;
  padding-bottom: 64px;
}

.store-filters {
  padding-top: 0px;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

@media (max-width: 576px) {
  .trending {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .store-filters {
    padding-top: 48px;
  }
}

td,
tr {
  color: black;
}
@keyframes samet {
  0% {
    width: 0;
  }
  50% {
    width: calc(100%);
    background: #7c7a78;
  }
  100% {
    width: 0%;
  }
}
